<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных интеграции
      </h4>
      <div class="alert-body">
        Не найдена интеграция с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'integrations'}"
        >
          Список интеграций
        </b-link>
        и выбрать другую.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="false"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-thead>
              <b-tr>
                <b-th colspan="2">
                  Яндекс Маркет FBS
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>
                  Тип
                </b-th>
                <b-td>
                  {{ get(types.find(i => i.value === item.type), 'label', '') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  ClientID
                </b-th>
                <b-td>
                  {{ item.client_id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Client secret
                </b-th>
                <b-td>
                  {{ item.client_secret }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Идентификатор бизнес-аккаунта
                </b-th>
                <b-td>
                  {{ item.business_id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Идентификатор кампании
                </b-th>
                <b-td>
                  {{ item.campaign }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Авторизационный токен кампании
                </b-th>
                <b-td>
                  {{ item.campaign_token }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Идентификатор склада кампании
                </b-th>
                <b-td>
                  {{ item.warehouse_id }}
                </b-td>
              </b-tr>

              <b-tr>
                <b-th>
                  НДС
                </b-th>
                <b-td>
                  <feather-icon
                    v-if="item.include_vat"
                    icon="CheckIcon"
                    class="text-success"
                    size="20"
                  />
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                    class="text-secondary"
                    size="20"
                  />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th>
                  Состояние
                </b-th>
                <b-td>
                  <div
                    v-if="!item.is_active && !item.error"
                    class=""
                  >
                    <b-alert
                      variant="warning"
                      show
                      class="mb-0"
                    >
                      <div class="alert-body">
                        <span>Ожидает подтверждения прав</span>
                      </div>

                    </b-alert>
                    <div class="mt-1">
                      <a
                        :href="confirmUrl"
                        target="_blank"
                        class="btn btn-outline-primary btn-sm mr-1"
                      >
                        Подтвердить
                      </a>
                      <span class="d-inline-block small">
                        После подтверждения обновите страницу!
                      </span>
                    </div>
                  </div>
                  <div
                    v-else-if="!item.is_active && item.error"
                    class=""
                  >
                    <b-alert
                      variant="danger"
                      show
                      class="mb-0"
                    >
                      <h4 class="alert-heading">
                        {{ item.error }}
                      </h4>
                      <div
                        v-if="item.error_description"
                        class="alert-body"
                      >
                        <span>{{ item.error_description }}</span>
                      </div>
                    </b-alert>
                  </div>
                  <div
                    v-else-if="item.is_active"
                    class=""
                  >
                    <b-badge
                      variant="success"
                      pill
                    >
                      Работает
                    </b-badge>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Использование в интеграции пользовательского идентификатора торгового предложения
                </b-th>
                <b-td>
                  <feather-icon
                    v-if="item.use_external_public_id"
                    icon="CheckIcon"
                    class="text-success"
                    size="20"
                  />
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                    class="text-secondary"
                    size="20"
                  />
                </b-td>
              </b-tr>

            </b-tbody>
            <b-thead>
              <b-tr>
                <b-th colspan="2">
                  Merlion
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>
                  Код клиента
                </b-th>
                <b-td>
                  {{ get(item, 'merlion_client', '-') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Логин
                </b-th>
                <b-td>
                  {{ get(item, 'merlion_login', '-') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Пароль
                </b-th>
                <b-td>
                  *******
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Час формирования суточного заказа
                </b-th>
                <b-td>
                  {{ get(item, 'daily_order_hour', '-') }}
                </b-td>
              </b-tr>
            </b-tbody>
            <b-thead>
              <b-tr>
                <b-th colspan="2">
                  Fresh Logic
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>
                  Логин
                </b-th>
                <b-td>
                  {{ get(item, 'fresh_login', '-') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Пароль
                </b-th>
                <b-td>
                  *******
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Email адрес менеджера
                </b-th>
                <b-td>
                  {{ get(item, 'fresh_manager_email', '-') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Час формирования письма
                </b-th>
                <b-td>
                  {{ get(item, 'daily_email_hour', '-') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Правила по цене
                </b-th>
                <b-td>
                  <template v-if="get(item, 'remain_by_price_rules', []).length">
                    <div
                      v-for="(priceRule, priceRuleIndex) in item.remain_by_price_rules"
                      :key="priceRuleIndex"
                    >
                      <div
                        v-if="priceRule.type === 'from'"
                      >
                        - Если цена от <strong>{{ priceRule.from }}</strong> выгружать от <strong>{{ priceRule.remain_count }}</strong> шт.
                      </div>
                      <div
                        v-if="priceRule.type === 'to'"
                      >
                        - Если цена до <strong>{{ priceRule.to }}</strong> выгружать от <strong>{{ priceRule.remain_count }}</strong> шт.
                      </div>
                      <div
                        v-if="priceRule.type === 'range'"
                      >
                        - Если цена от <strong>{{ priceRule.from }}</strong> и до <strong>{{ priceRule.to }}</strong>
                        выгружать от <strong>{{ priceRule.remain_count }}</strong> шт.
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Правила по марже
                </b-th>
                <b-td>
                  <template
                    v-if="get(item, 'remain_by_margin_rules', []).length"
                  >
                    <div
                      v-for="(marginRule, marginRuleIndex) in item.remain_by_margin_rules"
                      :key="marginRuleIndex"
                    >
                      <div
                        v-if="marginRule.type === 'from'"
                      >
                        - Если маржа от <strong>{{ marginRule.from }}</strong> выгружать от <strong>{{ marginRule.remain_count }}</strong> шт.
                      </div>
                      <div
                        v-if="marginRule.type === 'to'"
                      >
                        - Если маржа до <strong>{{ marginRule.to }}</strong> выгружать от <strong>{{ marginRule.remain_count }}</strong> шт.
                      </div>
                      <div
                        v-if="marginRule.type === 'range'"
                      >
                        - Если маржа от <strong>{{ marginRule.from }}</strong> и до <strong>{{ marginRule.to }}</strong>
                        выгружать от <strong>{{ marginRule.remain_count }}</strong> шт.
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-if="$store.getters['workingMode/checkPermission']('manage_integrations')">
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'integrations.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge,
  BTableSimple, BTbody, BThead, BTr, BTd, BTh,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref } from 'vue'

import {
  deleteMerlionFreshYmfbsIntegration as deleteMerlionFreshYmfbsIntegrationApi,
  getMerlionFreshYmfbsIntegration as getMerlionFreshYmfbsIntegrationApi,
} from '@/services/main-api/integrations/merlion-fresh-ymfbs'

import useCrudShow from '@/composables/useCrudShow'

import { types } from '@/composables/useIntegrations'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BThead,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const confirmUrl = ref('')

    const getItem = async () => {
      try {
        const res = await getMerlionFreshYmfbsIntegrationApi({ id: props.id })
        instance.item = {
          ...res.data.data,
          type: 'merlion-fresh-ymfbs',
        }

        confirmUrl.value = get(res, 'data.meta.oAuthYandexURL', '')
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        name: '',
      },
      getItem,
      deleteEntity: i => deleteMerlionFreshYmfbsIntegrationApi(i.id),
      getQuestionTextBeforeDeletion: i => `Интеграция ${i.name} будет удалена`,
      getTextAfterDeletion: () => 'Интеграция была удалена.',
      runAfterDeletion: () => {
        instance.$router.push({ name: 'integrations' })
      },
    })

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      types,
      confirmUrl,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
